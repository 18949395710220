

























































import { Component } from "vue-property-decorator";
import { UserManagementModule } from "@/store/modules/user-management-module";
import { FullDetailUserInterface } from "@/types/user.type";
import { DateConverterMixin } from "@/mixins/date-converter-mixin";
import { mixins } from "vue-class-component";
import { DurationHelperMixins } from "@/mixins/duration-helper-mixins";
import { RoleMixin } from "@/mixins/role-mixin";
import SearchField from "@/components/text-field/SearchField.vue";
import Header from "@/components/Header.vue";
import TableComponent from "@/components/TableComponent.vue";
import CloseButton from "@/components/buttons/CloseButton.vue";

@Component({
  name: "Users",
  components: {
    CloseButton,
    SearchField,
    Header,
    TableComponent,
  },
})
export default class extends mixins(DurationHelperMixins, RoleMixin, DateConverterMixin) {
  private headers = [
    { text: "Name", align: "start", value: "firstName" },
    { text: "Email", align: "start", value: "email" },
    { text: "Modified", align: "start", value: "updated" },
    { text: "Active", align: "center", value: "status", sortable: false },
    { text: "Leave Limits (Days)", align: "center", value: "durationLimit", sortable: false },
    { text: "", align: "center", value: "action", sortable: false },
  ];

  private groupActions = new Map<string, any>([
    ["None", null],
    ["Reset Leave Limits", this.resetLeaveLimits],
  ]);

  private leaveLimits = new Map<number, Map<number, number>>();

  private users: FullDetailUserInterface[] = [];
  private selectedUsers: { realId: string }[] = [];

  private groupAction: string = "None";

  private search: string = "";

  public async mounted() {
    await this.fetchUsers();
  }

  public getGroupItems() {
    return Array.from(this.groupActions.keys());
  }

  private async fetchUsers() {
    await UserManagementModule.getAllUsers();
    this.users = UserManagementModule.users;
    this.users.forEach((u) => {
      this.leaveLimits.set(u.id, new Map<number, number>());
      u.leaveRequestLimit.forEach((l: any) => {
        this.leaveLimits.get(u.id)?.set(l.reason.id, l.limit);
      });
    });
  }

  private updateSearch(txt: string) {
    this.search = txt;
  }

  private updateSelected(selected: { realId: string }[]) {
    this.selectedUsers = selected;
  }

  private async resetLeaveLimits() {
    await UserManagementModule.resetLeaveLimits(this.selectedUsers.map((u) => u.realId));
  }

  private async performGroupAction() {
    let action = this.groupActions.get(this.groupAction);
    if (action) {
      await action();
      this.fetchUsers();
    }
  }

  private goToRowDetail(data: any) {
    this.$router.push(`/users/${data.realId}/`);
  }
}
